import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { fromEvent, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root',
})
export class XamarinNavigationService {
  private readonly router = inject(Router);
  private readonly location = inject(Location);

  listenForXamarinNavigationEvents() {
    // document.dispatchEvent(new CustomEvent('navigateBack'));
    fromEvent(document, 'navigateBack').pipe(
      takeUntilDestroyed(),
      tap(() => this.location.back())
    ).subscribe();

    // document.dispatchEvent(new CustomEvent('navigateBack', { detail: { uuid: '123' } }));
    fromEvent(document, 'navigateToAlarming').pipe(
      takeUntilDestroyed(),
      tap((event: CustomEvent<{ uuid: string }>) => this.router.navigate([
        '/',
        'machine',
        event.detail.uuid,
        'details',
        'alarming',
      ]))
    ).subscribe();
  }
}
